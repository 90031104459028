.Main {

}

.Info {
    font-size: 10px;
}

.Phone {
    width: 85px;
    height: 50px;
    background: #f1f1f1;
    margin-right: 8px;
}
